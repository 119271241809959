import React, { useEffect } from "react";

const GvbDemo = () => {
    useEffect(() => {
        if ("window" in global) {
            window.open("https://app.supademo.com/showcase/clix85lbk0hl1zgteypzk4x06");
        }
    }, []);

    return (
        <div />
    );
};

export default GvbDemo;
